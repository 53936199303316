<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Filtre">
          <b-row>
            <b-col
              v-if="commerciaux.length"
              md="4"
            >
              <b-form-group
                label="Commerciaux"
                label-for="commerciaux"
              >
                <v-select
                  v-model="commercial"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="commerciaux"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                label="Semaines"
                label-for="semaines"
              >
                <v-select
                  v-model="semaine"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="weekRange"
                  :options="myGetWeekRange()"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                label="Années"
                label-for="annees"
              >
                <v-select
                  v-model="annee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="weekRange"
                  :options="[getCurrentYear() - 3,getCurrentYear() - 2,getCurrentYear() - 1, getCurrentYear()]"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-overlay
                  :show="show"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.distributeur-modal-prevent-closing
                    variant="outline-primary"
                    class="btn_export"
                    @click="filtrer"
                  >
                    <feather-icon icon="SearchIcon"/>
                    Filtrer
                  </b-button>
                </b-overlay>
              </div>

            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <ListeProduitsVisitesParSemaine
            :produits-visites="formaterDonneesPourVueGoodTable2()"
            :title="'SUIVI STOCK DISTRIBUTEURS'"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="item in stocksParSemaines"
        :key="item.distributeur_r.id"
        md="12"
      >
        <b-card>
          <ListeProduitsVisitesParSemaine
            :produits-visites="item.produits"
            :title="item.distributeur_r.nom"
          />
        </b-card>
      </b-col>
    </b-row>
    <!--    <b-row>-->
    <!--      <b-col md="12">-->
    <!--        <b-card-->
    <!--          v-if="title"-->
    <!--          :title="`${title}`"-->
    <!--        >-->
    <!--          &lt;!&ndash; input search &ndash;&gt;-->
    <!--          <div class="custom-search d-flex justify-content-end">-->
    <!--            <b-form-group>-->
    <!--              <div class="d-flex align-items-center">-->
    <!--                <label class="mr-1">Recherche-->
    <!--                </label>-->
    <!--                <b-form-input-->
    <!--                  v-model="searchTerm"-->
    <!--                  placeholder="Recherche"-->
    <!--                  type="text"-->
    <!--                  class="d-inline-block"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </b-form-group>-->
    <!--            &lt;!&ndash; primary &ndash;&gt;-->
    <!--            <b-dropdown-->
    <!--              id="dropdown-1"-->
    <!--              v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--              text="Exporter"-->
    <!--              variant="primary"-->
    <!--              class="ml-2 btn_export"-->
    <!--            >-->
    <!--              <b-dropdown-item @click="exportCSV">-->
    <!--                CSV-->
    <!--              </b-dropdown-item>-->
    <!--              <b-dropdown-item @click="exportExcel">-->
    <!--                MS-Excel-->
    <!--              </b-dropdown-item>-->
    <!--            </b-dropdown>-->
    <!--          </div>-->
    <!--          <vue-good-table-->
    <!--            id="list"-->
    <!--            ref="myTable"-->
    <!--            :columns="columns"-->
    <!--            :rows="produitsVisites"-->
    <!--            :rtl="direction"-->
    <!--            :search-options="{-->
    <!--              enabled: true,-->
    <!--              externalQuery: searchTerm }"-->
    <!--            :pagination-options="{-->
    <!--              enabled: true,-->
    <!--              perPage:pageLength-->
    <!--            }"-->
    <!--          >-->
    <!--            <template-->
    <!--              slot="table-row"-->
    <!--              slot-scope="props"-->
    <!--            >-->

    <!--              &lt;!&ndash; Column: Status &ndash;&gt;-->
    <!--              <span v-if="props.column.field === 'produit_r'">-->

    <!--              </span>-->

    <!--              &lt;!&ndash; Column: Action &ndash;&gt;-->
    <!--              <span v-else-if="props.column.field === 'action'">-->
    <!--                &lt;!&ndash;                  <feather-icon&ndash;&gt;-->
    <!--                &lt;!&ndash;                    icon="Edit2Icon"&ndash;&gt;-->
    <!--                &lt;!&ndash;                    class="mr-50"&ndash;&gt;-->
    <!--                &lt;!&ndash;                  />&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <span>Edit</span>&ndash;&gt;-->
    <!--                <router-link :to="{ name: 'zones-details', params: { id: props.row.id } }">-->
    <!--                  <b-button-->
    <!--                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
    <!--                    variant="outline-primary"-->
    <!--                  >-->
    <!--                    Voir-->
    <!--                  </b-button>-->
    <!--                </router-link>-->
    <!--              </span>-->

    <!--              &lt;!&ndash; Column: Common &ndash;&gt;-->
    <!--              <span v-else>-->
    <!--                {{ props.formattedRow[props.column.field] }}-->
    <!--              </span>-->
    <!--            </template>-->

    <!--            &lt;!&ndash; pagination &ndash;&gt;-->
    <!--            <template-->
    <!--              slot="pagination-bottom"-->
    <!--              slot-scope="props"-->
    <!--            >-->
    <!--              <div class="d-flex justify-content-between flex-wrap">-->
    <!--                <div class="d-flex align-items-center mb-0 mt-1">-->
    <!--                  <span class="text-nowrap">-->
    <!--                    Affichage de 1 à-->
    <!--                  </span>-->
    <!--                  <b-form-select-->
    <!--                    v-model="pageLength"-->
    <!--                    :options="['3','5','10']"-->
    <!--                    class="mx-1"-->
    <!--                    @input="(value)=>props.perPageChanged({currentPerPage:value})"-->
    <!--                  />-->
    <!--                  <span class="text-nowrap "> sur {{ props.total }} élements </span>-->
    <!--                </div>-->
    <!--                <div>-->
    <!--                  <b-pagination-->
    <!--                    :value="1"-->
    <!--                    :total-rows="props.total"-->
    <!--                    :per-page="pageLength"-->
    <!--                    first-number-->
    <!--                    last-number-->
    <!--                    align="right"-->
    <!--                    prev-class="prev-item"-->
    <!--                    next-class="next-item"-->
    <!--                    class="mt-1 mb-0"-->
    <!--                    @input="(value)=>props.pageChanged({currentPage:value})"-->
    <!--                  >-->
    <!--                    <template #prev-text>-->
    <!--                      <feather-icon-->
    <!--                        icon="ChevronLeftIcon"-->
    <!--                        size="18"-->
    <!--                      />-->
    <!--                    </template>-->
    <!--                    <template #next-text>-->
    <!--                      <feather-icon-->
    <!--                        icon="ChevronRightIcon"-->
    <!--                        size="18"-->
    <!--                      />-->
    <!--                    </template>-->
    <!--                  </b-pagination>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </template>-->
    <!--          </vue-good-table>-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
  </div>

</template>

<script>
import {
  BButton,
  BCard, BCol, BFormGroup, BOverlay, BRow,
} from 'bootstrap-vue'
import { API_URL } from '@/helpers/global-scops'
import vSelect from 'vue-select'
import { getCurrentWeekRange, getWeekRange } from '@/helpers/methodes'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import ListeProduitsVisitesParSemaine from '@/views/afridia/distributeurs/details/ListeProduitsVisitesParSemaine.vue'

export default {
  name: 'MainVue',
  components: {
    BOverlay,
    ListeProduitsVisitesParSemaine,
    BButton,
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,

  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  data() {
    return {
      show: false,
      currentYear: new Date().getFullYear(),
      commerciaux: [],
      commercial: null,
      semaine: null,
      semaines: [],
      annee: null,
      annees: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Produit',
          field: 'produit_r.produit_r.nom_produit',
        },
        {
          label: 'Stock 1e Visite',
          field: 'stock1',
        },
        {
          label: 'Stock 2e Visite',
          field: 'stock2',
        },
        {
          label: 'DLUO',
          field: 'dluo',
        },
        {
          label: 'Entrée',
          field: 'stockCommande',
        },
        {
          label: 'Sortie',
          field: 'stockSortie',
        },
      ],
      stocksParSemaines: [],
      stocksParSemaines1: [],
    }
  },
  mounted() {
    this.getCommercials()
    this.semaine = getCurrentWeekRange()
  },
  methods: {
    filtrer() {
      this.show = true
      const commercialId = this.commercial ? this.commercial.uuid : null
      this.$http.get(`${API_URL}semaine-visite-distributeur/?commercial_id=${commercialId}&semaine_number=${this.semaine.weekNumber}`)
        .then(response => {
          this.stocksParSemaines1 = response.data
          const groupByDistributeurs = []
          // eslint-disable-next-line no-restricted-syntax
          for (const produitStocks of response.data) {
            const distributeur = produitStocks.distributeur_r
            const produit = produitStocks.produit_r
            const { stock1 } = produitStocks
            const { stock2 } = produitStocks
            const { dluo } = produitStocks
            const { stockCommande } = produitStocks
            const { stockSortie } = produitStocks
            const semaine = produitStocks.semaine_label
            const { annee } = produitStocks
            const index = groupByDistributeurs.findIndex(d => d.distributeur_r.id === distributeur.id)
            if (index === -1) {
              groupByDistributeurs.push({
                distributeur_r: distributeur,
                produits: [{
                  produit_r: produit,
                  stock1,
                  stock2,
                  dluo,
                  stockCommande,
                  stockSortie,
                  semaine,
                  annee,
                }],
              })
            } else {
              groupByDistributeurs[index].produits.push({
                produit_r: produit,
                stock1,
                stock2,
                dluo,
                stockCommande,
                stockSortie,
                semaine,
                annee,
              })
            }
          }
          this.show = false
          // console.log(groupByDistributeurs)
          // eslint-disable-next-line no-restricted-syntax
          // for (const distributeur of groupByDistributeurs) {
          //   distributeur.semaines = this.regrouperParSemaineLabel(distributeur.semaines)
          //   newGroupByDistributeurs.push(distributeur)
          // }
          // console.log(newGroupByDistributeurs)
          // console.log(this.stocksParSemaines)
          this.stocksParSemaines = groupByDistributeurs
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    getCurrentYear() {
      return new Date().getFullYear()
    },
    myGetWeekRange() {
      if (this.annee) {
        return getWeekRange(this.annee)
      }
      return getWeekRange(this.currentYear)
    },
    getCommercials() {
      this.$http.get(`${API_URL}commercials/`)
        .then(response => {
          this.commerciaux = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    regrouperParSemaineLabel(tableau) {
      return tableau.reduce((regroupement, objet) => {
        const {
          semaine_label,
          ...autresProps
        } = objet

        if (!regroupement[semaine_label]) {
          // eslint-disable-next-line no-param-reassign
          regroupement[semaine_label] = []
        }

        regroupement[semaine_label].push(autresProps)

        return regroupement
      }, {})
    },

    // formater les données pour vue-good-table afin d'avoir la somme de chaque produit par stock
    formaterDonneesPourVueGoodTable() {
      const data = []
      this.stocksParSemaines.forEach(distributeur => {
        distributeur.produits.forEach(produit => {
          const index = data.findIndex(d => d.produit_r.id === produit.produit_r.id)
          if (index === -1) {
            data.push({
              produit_r: produit.produit_r,
              stock1: produit.stock1,
              stock2: produit.stock2,
              dluo: produit.dluo,
              stockCommande: produit.stockCommande,
              stockSortie: produit.stockSortie,
            })
          } else {
            data[index].stock1 += produit.stock1
            data[index].stock2 += produit.stock2
            data[index].stockCommande += produit.stockCommande
            data[index].stockSortie += produit.stockSortie
          }
        })
      })
      return data
    },
    formaterDonneesPourVueGoodTable2() {
      const data = []
      this.stocksParSemaines1.forEach(produit => {
        const index = data.findIndex(d => d.produit_r.produit_id === produit.produit_r.produit_id)
        if (index === -1) {
          data.push({
            produit_r: produit.produit_r,
            stock1: produit.stock1,
            stock2: produit.stock2,
            dluo: produit.dluo,
            stockCommande: produit.stockCommande,
            stockSortie: produit.stockSortie,
          })
        } else {
          data[index].stock1 += produit.stock1
          data[index].stock2 += produit.stock2
          data[index].stockCommande += produit.stockCommande
          data[index].stockSortie += produit.stockSortie
        }
      })
      return data
    },
  },
}
</script>
